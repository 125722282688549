                        // CAROUSEL
function Home() {

  return(
    <>
      <div id="carouselSolar" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselSolar" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselSolar" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselSolar" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner ">
          <div className="carousel-item active">
              <img src="/images/carousel-img1.jpg" className="d-block w-100" height="460px" alt="power" />
              <div className="carousel-text d-block p-2">
                  <h1>Power your home and business with solar energy</h1>
              </div>
          </div>
          <div className="carousel-item">
              <img src="/images/carousel-img2.jpg" className="d-block w-100" height="460px" alt="robust" />
              <div className="carousel-text d-block">
                  <h1>Robust Solar PV structure</h1>
              </div>
          </div>
          <div className="carousel-item">
              <img src="/images/carousel-img3.jpg" className="d-block w-100" height="460px" alt="cusutomised" />
              <div className="carousel-text d-block">
                  <h1>Customized solutions as per your budget</h1>
              </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselSolar" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselSolar" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container-middle">
        <h1 className="m-4">Benefits of Solar PV systems</h1>
        <div className="row row-gap-3 mb-4">
          <div className="col-lg-4 col-md-6">
            <div className="card border-light rounded-2 feature-column shadow">
              <i className="fa-brands fa-pagelines card-img-top features-icon fa-5x p-4"></i>
              <div className="card-body">
                 <h4 className="card-title">A greener tomorrow</h4>
                 <p className="card-text text-body-secondary mt-3">
                   Shifting from fossil fuels to solar energy helps reduce greenhouse gas emissions, combat climate change, and promote a healthier planet.
                 </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card border-light rounded-2 feature-column shadow">
              <i className="fa-solid fa-piggy-bank features-icon fa-5x p-4"></i>
              <div className="card-body">
                 <h4 className="card-title">Costs less than you think</h4>
                 <p className="card-text text-body-secondary mt-3">
                   Solar PV systems can yield substantial financial returns, typically allowing users to recover their initial investment within 3 to 4 years, while providing significant reductions in electricity bills and long-term savings over their average lifespan of 30 years.
                 </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mx-auto">
            <div className="card border-light rounded-2 feature-column shadow">
              <i className="fa-solid fa-recycle features-icon fa-5x p-4"></i>
              <div className="card-body pt-0">
                 <h4 className="card-title">Easy to manage</h4>
                 <p className="card-text text-body-secondary mt-3">
                   Solar PV systems offer easy-to-use dashboards for real-time tracking of energy production. They are scalable, allowing for simple expansion as energy requirements change. Furthermore, solar panels require very little maintenance, making them a cost-effective choice over time.
                 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;