
function Services(){
    return(
        <><div className="container-common ">
            <h1 className="p-2">Our Services</h1>
                    {/* CARD 1 */}
            <div className="card mb-3 border-light rounded-2 shadow mt-4">
              <div className="row g-0">
                  <div className="col-md-6">
                   <img src="/images/services-planning.jpg" className="img-fluid services-image rounded-start " alt="..."/>
                  </div>
                  <div className=" col-md-6 ps-2">
                    <div className="card-body services-text">
                     <h5 className="card-title">Planning and Development</h5>
                     <p className="card-text text-body-secondary pt-2">We conduct comprehensive feasibility studies to provide tailored solutions, meticulously tracking project goals, tasks, resources, schedules, costs, and contingencies. Our approach ensures the highest quality of work while keeping the project on time and within budget until successful closure.</p>
                    </div>
                  </div>
               </div>
            </div>
            <div className="card mb-3 border-light shadow textBlock mt-4">
               <div className="row g-0 imageBlock">    
                 <div className="col-md-6 ps-2">
                    <div className="card-body services-text">
                     <h5 className="card-title">Designing and Engineering</h5>
                     <p className="card-text text-body-secondary pt-2">Our AI-powered software generates optimized designs based on geographical location and environmental conditions to maximize energy throughput and optimize surface utilization. The design process incorporates both electrical design—detailing how the system will operate for maximum efficiency—and structural design—ensuring proper mounting and support for long-term durability and performance.</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img src="/images/services-designing.jpg" className="img-fluid rounded-end services-image" alt="..."/>
                  </div>
               </div>
            </div>
            <div className="card mb-3 border-light rounded-2 shadow mt-4">
               <div className="row g-0">
                  <div className="col-md-6">
                    <img src="/images/services-permits.jpg" className="img-fluid services-image rounded-start" alt="..."/>
                  </div>
                  <div className=" col-md-6 ps-2">
                    <div className="card-body services-text ">
                     <h5 className="card-title">Permits and Authorizations</h5>
                     <p className="card-text text-body-secondary pt-2">When installing a photovoltaic (PV) system for residential or commercial use, obtaining the necessary permits, licenses, and authorizations is essential to ensure compliance with local and state regulations. These approvals are typically secured before any physical work begins, ensuring the project adheres to all legal and safety requirements.</p>
                  </div>
                 </div>
              </div>
            </div>
            <div className="card mb-3 border-light shadow textBlock mt-4">
              <div className="row g-0 imageBlock">    
                 <div className=" col-md-6 ps-2">
                   <div className="card-body services-text">
                     <h5 className="card-title">Procurement, Installation and Commissioning</h5>
                     <p className="card-text text-body-secondary pt-2">We source and acquire the highest quality components and equipment, adhering to engineering specifications to ensure the system's reliability with all regulatory standards.
                        During installation, we ensure that our work aligns with the agreed-upon timelines, quality standards, and budget commitments.
                        The commissioning of a Solar PV system involves thorough testing, verification, and confirmation that all components are properly installed, integrated, and performing in accordance with the design and performance specifications.</p>
                    </div>
                    </div>
                    <div className="col-md-6 "> 
                    <img src="/images/services-installation.jpg" className="img-fluid rounded-end services-image" alt="..."/>
                   </div>
              </div>
            </div>
            <div className="card mb-3 border-light rounded-2 shadow mt-4" >
              <div className="row g-0  ">
                  <div className="col-md-6">
                    <img src="/images/services-maintenance.jpg" className="img-fluid services-image rounded-start " alt="..."/>
                  </div>
                  <div className=" col-md-6 ps-2">
                    <div className="card-body services-text">
                      <h5 className="card-title">Operation and Maintenance</h5>
                      <p className="card-text text-body-secondary pt-2">
                        <ul>
                          <li>
                            <b>Professional Deep Cleaning: </b>We ensure optimal system performance through thorough cleaning of solar panels.
                          </li>
                          <li>
                            <b>Preventive Health Checks: </b>Our team of experts conducts regular assessments to maintain system efficiency, including
                            inspections of fire extinguishers, earthing systems, and lightning arrestors.
                          </li>
                          <li>
                            <b>Real-Time Remote Monitoring: </b>We monitor system performance to detect and address any issues promptly.
                          </li>
                        </ul>
                      </p>
                    </div>
                   </div>
                </div>
              </div>
             <div className="card mb-3 border-light shadow textBlock mt-4">
                <div className="row g-0 imageBlock">    
                   <div className=" col-md-6 ps-2">
                     <div className="card-body services-text">
                     <h5 className="card-title">Easy Pay Plans</h5>
                     <p className="card-text text-body-secondary pt-2">Our mission is to make solar affordable to all, for that we provide easy payment plans with competitive rates.</p>
                    </div>
                   </div>
                   <div className="col-md-6 "> 
                    <img src="/images/services-easy plans.jpg" className="img-fluid rounded-end services-image" alt="..."/>
                   </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Services;