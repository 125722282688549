import {
  useNavigate,
} from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const onNavigate = (pathname) => {
    navigate({
      pathname,
    });
  };

  return (
    <div className="mt-5" id="home">
      <nav className="navbar navbar-expand-lg fixed-top px-5 shadow">

        <a className="brand-logo" onClick={() => onNavigate('/')}>
          <img id="logoImage" src="./images/logo.png" alt="Company Logo"/>
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerModule" aria-controls="navbarTogglerModule" aria-expanded="false"
                aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerModule">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link focus" onClick={() => onNavigate('/')} data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link focus" onClick={() => onNavigate('/about')} data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">About Us</a>
            </li>
            <li className="nav-item">
              <a className="nav-link focus" onClick={() => onNavigate('/services')} data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Services</a>
            </li>
            <li className="nav-item">
              <a className="nav-link focus" onClick={() => onNavigate('/contact')} data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;