function Contact() {
  return(
   <div className="container-common">
      <h1 className="p-2">Get In Touch</h1>
      <div className="d-lg-flex d-md-flex justify-content-lg-between align-items-md-stretch flex-md-wrap flex-lg-wrap my-4 bg-white">
         <div className="contact-card order-md-3 order-lg-0 shadow me-lg-2 me-md-2">
           <i className="fa-solid fa-map-location-dot fa-3x m-4"></i>
              <p>A1002 Balaji Generosia,<br/>
              Behind Spice Court Restaurant,<br/>
              Near Bangalore Pune highway,<br/>
              Baner,<br/>
              Pune - 411045
           </p>
         </div>
          {/* <!-- PHONE --> */}
        <div className="contact-card shadow me-lg-2 me-md-2">
           <i className="fa-solid fa-phone fa-3x m-4"></i>
           <h5><a className="contact-style" href="tel:+919272100451">+919272100451</a></h5>
           <h5><a className="contact-style" href="tel:+919272100452">+919272100452</a></h5>
           <a className="contact-style" target="_blank" aria-label="Chat on WhatsApp" href="https://wa.me/+919272100451?text=More%20info%20about%20your%20services%20please"> <img src="/images/whatsapp_logo.png" className="m-4" style={{width: "45px", height: "45px"}}></img>
           <h5>Whatsapp us</h5>
           </a>
        </div>
          {/* <!-- EMAIL --> */}
        <div className="contact-card shadow">
           <i className="fa-solid fa-envelope fa-3x m-4"></i> 
           <h6>Email us to get more info.</h6>
           <h5><a className="contact-style " href="mailto:sales@omniecoshield.in">sales@omniecoshield.in</a></h5>
        </div>
        <div className="contact-card p-0 order-md-4 shadow mt-1">
         <p><iframe width="100%"  height="315px" src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=A1002 Balaji Generosia, Near Bangalore Pune highway, baner, pune 411045&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" title="address"></iframe></p>
      </div>
       </div>   
      
 </div>
 );
}

export default Contact;
