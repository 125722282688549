function About() {
  return (
    <>
      <div className="container-common" id="about">
        <h1 className="p-2">About Us</h1>
        <p className="lh-md p-3 fs-4">
          We are dedicated to transforming the way you harness energy. Our focus is on delivering innovative, efficient, and sustainable solar energy solutions tailored to the unique needs of our clients.
        </p>
      </div>
      <div className="container-middle">
        <div className="row row-gap-3 mb-4">
          <div className="col-lg-4 col-md-6">
            <div className="card border-light rounded-2 feature-column shadow">
              <img className="aboutus-columns" src="/images/mission.png" alt="mission"/>
              <div className="card-body">
                <h4 className="card-title">Mission</h4>
                <p className="card-text text-body-secondary mt-3">
                  We aim to raise awareness about the benefits of solar energy while providing comprehensive support throughout the installation and maintenance processes. Our goal is to empower customers to generate their own energy, resulting in lower energy costs and enhanced energy security.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card border-light rounded-2 feature-column shadow">
              <img className="aboutus-columns" src="/images/vision.png" alt="vision"/>
              <div className="card-body">
                <h4 className="card-title">Vision</h4>
                <p className="card-text text-body-secondary mt-3">
                  Our vision is to make solar energy accessible and affordable for everyone. We strive to create clean and reliable energy solutions by partnering with governments, organizations, and individuals, paving the way for a sustainable future.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mx-auto">
            <div className="card border-light rounded-2 feature-column shadow">
              <img className="aboutus-columns" src="/images/values.png" alt="values"/>
              <div className="card-body">
                <h4 className="card-title">Values</h4>
                <p className="card-text text-body-secondary mt-3">
                  We prioritize environmentally responsible practices, use only the highest quality materials and technologies for lasting performance, and uphold transparency and accountability in all our relationships with clients and partners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;